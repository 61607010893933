import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { 
  Container, 
  Row,
  Col,
  Form,
  Badge,
  OverlayTrigger,
} from 'react-bootstrap';

import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

import { ExperiencePopover } from '../popovers/ExperiencePopover';
import { ScoringDetailsPopover } from '../popovers/ScoringDetailsPopover';
import { ANSWER_TAGS, INTERVIEW_TAGS } from 'features/Scoring/constants/enums';

import { buildMarks, scrollToWithContainer, renderTooltip, compare } from 'features/Scoring/utils/utils';

import { updateTotals, updateQutterScore, updateChatDetail } from 'features/Scoring';

export const ScoreBoardDetail = ({ id, idx }) => {
  // ({ detail, idx }) => {
  console.log(`<ScoreBoardDetail />`, id);
  const dispatch = useDispatch();
  const detail = useSelector(state => state.scoring.details[id]);

  if (!detail) {
    return <div>Loading...</div>;
  }

  const onSliderChange = value => {
    console.log(value);
    const id = detail.hasOwnProperty('category_id') ? detail.category_id: 
                detail.hasOwnProperty('form_id') ? detail.form_id: 
                detail.qutter_id;
    dispatch(updateQutterScore({
      id,
      scale_max: detail.scale[1],
      value,
    }));
    dispatch(updateTotals());

    dispatch(updateChatDetail({
      id,
      category_id: detail.category_id
    }));
  };

  const validation = detail.validation ? detail.validation: null;
  const tags = (validation && validation.tags && validation.tags.length ? validation.tags: null);
  const severity_degree = (validation && validation.severity_degree ? validation.severity_degree: null);

  let bg = '';
  if (detail.auto_score_success === false) {
    if (severity_degree && severity_degree === 1)
      bg = 'score-warning'
    else if (severity_degree && severity_degree === 2)
      bg = 'score-fail'
    else bg = 'bg-soft-dark'
  }
  else bg = 'score-success';

  const cats = { ...detail.categories };
  let categories = [];
  if (cats) {
    categories = Object.keys(cats).map((key, idx) => {
      return {
        ...cats[key],
        label: key
      }
    });
    categories = categories.sort(compare);
  }

  const type = detail.type;
  const weight = detail.weight ? detail.weight: 0;
  const score = detail.score ? detail.score: 0;
  const value = detail.value ? detail.value: 0;
  const predicted = detail.predicted ? detail.predicted: null;
  let edited = null;
  if ('edited' in detail) edited = detail.edited;
  else 
  if (predicted || predicted === 0) {
    if (predicted !== value) edited = true;
    else edited = false;
  }

  return (
    <Container 
      id={`score-table-row-${detail.qutter_id}`} 
      className={`p-1 ${bg}`} 
      style={{ border: '1px gray solid' }}
    >
      <Row key={`popover-row-${idx}`} className='mb-1'>
        <Col>
          <Form.Label>
            {detail.displayed_score || detail.displayed_score === 0 ? (
              <span>{detail.displayed_score.toFixed(0)}</span>
            ):(null)}
            <span 
              style={{ fontSize: '10px' }}
              dangerouslySetInnerHTML={{ __html: detail.name }}
            />
            {predicted !== null && !isNaN(predicted) && (
              <span className='d-block font-size-10'>Predicted: {predicted.toFixed(1)}</span>
            )}
          </Form.Label>

          <ScoringDetailsPopover
            name={detail.name}
            header={'Details'}
            is_weighted={detail.is_weighted}
            score={score}
            predicted={predicted}
            edited={edited}
            weight={weight}
          />

        </Col>
        <Col>
          <Slider
            min={detail.scale[0]}
            max={detail.scale[1]}
            step={detail.step ? detail.step: .2}
            defaultValue={value}

            dots
            marks={buildMarks(detail.scale[0], detail.scale[1])}
            onFocus={() => {
              scrollToWithContainer(detail.qutter_id)
              var element = document.getElementById(`${detail.qutter_id}`);
              if (element) {
                element.classList.add('red');
                document.getElementById(`score-table-row-${detail.qutter_id}`).classList.add('highlight-score-table-row');
              }
            }}
            onBlur={() => {
              var element = document.getElementById(`${detail.qutter_id}`);
              element.classList.remove('red');
              document.getElementById(`score-table-row-${detail.qutter_id}`).classList.remove('highlight-score-table-row');

              // Update chat detail
              dispatch(updateChatDetail({
                id: detail.qutter_id,
                category_id: detail.category_id
              }));

            }}
            onChange={onSliderChange}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          {tags && tags.length ? (
            tags.map((tag, idx) => {
              console.log('tag', tag)

              if (tag in ANSWER_TAGS) {
                if (tag === 'PASSED') return (null);
                else {
                  return (
                    <OverlayTrigger
                      key={`tag-overlay-${detail.qutter_id}-${idx}`}
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip({ description: ANSWER_TAGS[tag].description })}
                    >
                      <Badge bg="primary" className="me-2">{tag.split('_').join(' ')}</Badge>
                    </OverlayTrigger>
                  )
                }
              } else {
                return (
                  <Badge bg="primary" className="me-2">{tag.split('_').join(' ')}</Badge>
                )
              }

            })
          ):(null)}
        </Col>

        <Col>
        {categories && categories.length ? (
          categories.map((category, idx) => {
            return (
              <ExperiencePopover
                key={`experience-category-${detail.qutter_id}-${idx}`}
                header={category.label}
                data={category}
              />
            )
          })
        ):(null)}
        </Col>

      </Row>
    </Container>
  );
};
